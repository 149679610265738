exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-firm-2-js": () => import("./../../../src/pages/dla-firm-2.js" /* webpackChunkName: "component---src-pages-dla-firm-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opieka-medyczna-js": () => import("./../../../src/pages/opieka-medyczna.js" /* webpackChunkName: "component---src-pages-opieka-medyczna-js" */),
  "component---src-pages-opieka-medyczna-v-2-js": () => import("./../../../src/pages/opieka-medyczna-v2.js" /* webpackChunkName: "component---src-pages-opieka-medyczna-v-2-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-prywatna-opieka-medyczna-v-2-js": () => import("./../../../src/pages/prywatna-opieka-medyczna-v2.js" /* webpackChunkName: "component---src-pages-prywatna-opieka-medyczna-v-2-js" */),
  "component---src-pages-thank-you-page-js": () => import("./../../../src/pages/thank-you-page.js" /* webpackChunkName: "component---src-pages-thank-you-page-js" */),
  "component---src-pages-thank-you-page-v-2-js": () => import("./../../../src/pages/thank-you-page-v2.js" /* webpackChunkName: "component---src-pages-thank-you-page-v-2-js" */)
}

