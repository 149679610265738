/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
 import React from 'react';
import { AppProvider } from "./src/components/context/AppContext";

// You can delete this file if you're not using it
export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
